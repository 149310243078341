import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  OutlinedInput,
  Skeleton,
  Button,
  InputAdornment,
  SvgIcon,
  Link,
} from "@mui/material";
import FooterBg from "../../assets/images/footer-bg.png";

import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { ReactComponent as StarIcon } from "src/assets/icons/star.svg";
import { t, Trans } from "@lingui/macro";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip";
import { ReactComponent as ContributionIcon } from "src/assets/icons/contribution.svg";
import ContributionTitle from "src/assets/images/contribution-title.png";
import { useAppSelector, useMobile } from "../../hooks";
import Record from "../../components/Record/Record";
import FlowData from "./FlowData";
import RankingList from "./RankingList";
import { useWeb3Context } from "src/hooks/web3Context";
import { getContributionData, getRankingList } from "src/slices/CommunitySlice";
import { customNumberFormat, formatCurrency, trim } from "src/helpers";
import { addresses } from "src/constants";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import {
  approveForDistributor,
  contributionBurn,
  contributionClaim,
  getContributionRecordsByPage,
  getTokenContributionRecordsByPage,
} from "src/slices/ReleaseSlice";
import {
  getBalances,
  getBurnAmount,
  getBurnTokenAmount,
  loadAccountDetails,
} from "src/slices/AccountSlice";
import { error } from "src/slices/MessagesSlice";
import BurnNVBTab from "./BurnNVBTab";
import { ethers } from "ethers";

const claimLevelObj: any = {
  "15": 6,
  "30": 5,
  "60": 4,
  "100": 3,
  "150": 2,
  "180": 1,
};

const Contribution = () => {
  const { isSmallScreen } = useMobile();
  const { provider, address, connected, connect, chainID } = useWeb3Context();

  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [recordTab, setRecordTab] = useState(0);
  const [burnTab, setBurnTab] = useState(0);
  const [days, setDays] = useState<number>(15);
  const [nvbDays, setNVBDays] = useState<number>(15);
  const [destroyedValue, setDestroyedValue] = useState<any>();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [loadingSign, setLoadingSign] = useState<boolean>(false);
  const contributionPrincipalAmt = useAppSelector((state) => {
    return (
      state.account.balances && state.account.balances.contributionPrincipalAmt
    );
  });
  const maxPrincipal = useAppSelector((state) => {
    return state.account.balances && state.account.balances.maxPrincipal;
  });
  const ohmBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.ohm;
  });
  const pendingTransactions = useAppSelector((state) => {
    return state.pendingTransactions;
  });
  const totalClaimableReward = useAppSelector(
    (state) => state.community.totalClaimableReward
  );
  const totalContribution = useAppSelector(
    (state) => state.community.totalContribution
  );
  const totalBondReward = useAppSelector(
    (state) => state.community.totalBondReward
  );
  const totalNewContribution = useAppSelector(
    (state) => state.community.totalNewContribution
  );
  const totalTopContribution = useAppSelector(
    (state) => state.community.totalTopContribution
  );
  const rewardDistributorAllowance = useAppSelector((state) => {
    return state.account.rewardDistributorAllowance;
  });
  const bTokenrewardDistributorAllowance = useAppSelector((state) => {
    return state.account.bTokenrewardDistributorAllowance ?? 0;
  });

  const teamStaking = useAppSelector((state) => {
    return state.community.teamStaking;
  });
  const teamContribution = useAppSelector((state) => {
    return state.community.teamContribution;
  });
  const burnAmt = useAppSelector((state) => {
    return state.community.burnAmt;
  });
  const contributionRecords = useAppSelector((state) => {
    return state.release.contributionRecords;
  });
  const contributionTokenRecords = useAppSelector((state) => {
    return state.release.contributionTokenRecords;
  });
  const burnAmt15 = useAppSelector((state) => {
    return state.account.teamBurn && state.account.teamBurn.amt15;
  });
  console.log("🚀 ~ burnAmt15 ~ burnAmt15:", burnAmt15);

  const burnAmt30 = useAppSelector((state) => {
    return state.account.teamBurn && state.account.teamBurn.amt30;
  });
  const burnAmt60 = useAppSelector((state) => {
    return state.account.teamBurn && state.account.teamBurn.amt60;
  });
  const burnAmt100 = useAppSelector((state) => {
    return state.account.teamBurn && state.account.teamBurn.amt100;
  });
  const burnAmt150 = useAppSelector((state) => {
    return state.account.teamBurn && state.account.teamBurn.amt150;
  });
  const burnAmt180 = useAppSelector((state) => {
    return state.account.teamBurn && state.account.teamBurn.amt180;
  });
  const burnTokenAmt15 = useAppSelector((state) => {
    return state.account.teamTokenBurn && state.account.teamTokenBurn.amt15;
  });
  const burnTokenAmt30 = useAppSelector((state) => {
    return state.account.teamTokenBurn && state.account.teamTokenBurn.amt30;
  });
  const burnTokenAmt60 = useAppSelector((state) => {
    return state.account.teamTokenBurn && state.account.teamTokenBurn.amt60;
  });
  const burnTokenAmt100 = useAppSelector((state) => {
    return state.account.teamTokenBurn && state.account.teamTokenBurn.amt100;
  });
  const burnTokenAmt150 = useAppSelector((state) => {
    return state.account.teamTokenBurn && state.account.teamTokenBurn.amt150;
  });
  const burnTokenAmt180 = useAppSelector((state) => {
    return state.account.teamTokenBurn && state.account.teamTokenBurn.amt180;
  });
  const bTokenBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.bTokenBalance;
  });
  const wbnbBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.wbnb;
  });
  const isAppLoading = useAppSelector((state) => state.app.loading);

  const burnObj: any = {
    "15": burnAmt15 ?? 0,
    "30": burnAmt30 ?? 0,
    "60": burnAmt60 ?? 0,
    "100": burnAmt100 ?? 0,
    "150": burnAmt150 ?? 0,
    "180": burnAmt180 ?? 0,
  };
  const burnTokenObj: any = {
    "15": burnTokenAmt15 ?? 0,
    "30": burnTokenAmt30 ?? 0,
    "60": burnTokenAmt60 ?? 0,
    "100": burnTokenAmt100 ?? 0,
    "150": burnTokenAmt150 ?? 0,
    "180": burnTokenAmt180 ?? 0,
  };

  const verifyWallet = async () => {
    try {
      if (!provider || !address) return false;

      // 检查 localStorage 是否已验证
      const verifiedAddresses = JSON.parse(
        localStorage.getItem("verifiedAddresses") || "{}"
      );

      // 如果已验证,验证签名是否匹配
      if (verifiedAddresses[address]) {
        const storedSignature = verifiedAddresses[address];
        const message = `Welcome to NovaBank,\nSignature Verification Identity View Data.`;
        const recoveredAddress = ethers.utils.verifyMessage(
          message,
          storedSignature
        );

        if (recoveredAddress.toLowerCase() === address.toLowerCase()) {
          setIsVerified(true);
          return true;
        }
      }

      const signer = provider;
      const message = `Welcome to NovaBank,\nSignature Verification Identity View Data.`;
      setLoadingSign(true);
      const signature = await signer.signMessage(message);

      // 验证签名
      const recoveredAddress = ethers.utils.verifyMessage(message, signature);
      const isValid = recoveredAddress.toLowerCase() === address.toLowerCase();

      if (isValid) {
        // 存储签名而不是布尔值
        verifiedAddresses[address] = signature;
        localStorage.setItem(
          "verifiedAddresses",
          JSON.stringify(verifiedAddresses)
        );
        setIsVerified(true);
        setLoadingSign(false);

        return true;
      }

      return false;
    } catch (error) {
      setLoadingSign(false);

      console.error("Verification failed:", error);
      return false;
    }
  };

  const changeTab = async (_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
  };

  const changeRecordTab = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setRecordTab(newTab);
  };

  const changeBurnTab = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setBurnTab(newTab);
  };

  const handleSelect = (_days: number) => {
    setDays(_days);
  };

  const handleNVBSelect = (_days: number) => {
    setNVBDays(_days);
  };

  const verifyAddr = () => {
    const verifiedAddresses = JSON.parse(
      localStorage.getItem("verifiedAddresses") || "{}"
    );

    // 如果已验证,验证签名是否匹配
    if (verifiedAddresses[address]) {
      const storedSignature = verifiedAddresses[address];
      const message = `Welcome to NovaBank,\nSignature Verification Identity View Data.`;
      const recoveredAddress = ethers.utils.verifyMessage(
        message,
        storedSignature
      );

      if (recoveredAddress.toLowerCase() === address.toLowerCase()) {
        setIsVerified(true);
      }
    }
  };

  useEffect(() => {
    if (address) {
      verifyAddr();
      dispatch(
        getContributionData({
          provider,
          networkID: chainID,
          address,
        })
      );
      dispatch(
        getBalances({
          provider,
          networkID: chainID,
          address,
        })
      );
    }
  }, [address, connected, chainID, dispatch, provider]);

  useEffect(() => {
    // if (tab == 1) {
    dispatch(
      getRankingList({
        page: 1,
        provider,
        networkID: chainID,
      })
    );

    // }
  }, [tab]);

  useEffect(() => {
    if (address) {
      dispatch(
        getContributionRecordsByPage({
          address,
          page: 1,
          provider,
          networkID: chainID,
        })
      );
      dispatch(
        getTokenContributionRecordsByPage({
          address,
          page: 1,
          provider,
          networkID: chainID,
        })
      );
    }
  }, [address]);

  const handleBuyBToken = () => {
    window.open(
      `https://pancakeswap.finance/swap?chain=${chainID === 56 ? "bsc" : "bscTestnet"
      }&inputCurrency=${addresses[chainID].USDT_ADDRESS}&outputCurrency=${addresses[chainID].bToken
      }`,
      "_blank"
    );
  };
  console.log("rewardDistributorAllowance", rewardDistributorAllowance);
  const changeDestroyedValue = (e: any) => {
    let value = e.target.value.replace(/[^\d.]/g, "");
    if (value < 0) {
      setDestroyedValue("");
      return;
    }
    setDestroyedValue(value);
  };

  const claimTeamReward = async (isBurnToken = false) => {
    if (Number(burnObj[days]) > Number(wbnbBalance) && !isBurnToken) {
      return dispatch(error(t`WBNB balance is insufficient to destroy!`));
    }
    if (isBurnToken) {
      await dispatch(
        contributionClaim({
          address,
          provider,
          networkID: chainID,
          burnAmt:
            Number(totalClaimableReward) == 0 ? "0" : burnTokenObj[nvbDays],
          level: claimLevelObj[nvbDays],
          isBurnToken,
        })
      );
    } else {
      await dispatch(
        contributionClaim({
          address,
          provider,
          networkID: chainID,
          burnAmt: Number(totalClaimableReward) == 0 ? "0" : burnObj[days],
          level: claimLevelObj[days],
          isBurnToken,
        })
      );
    }

    if (isBurnToken) {
      dispatch(
        getTokenContributionRecordsByPage({
          address,
          provider,
          networkID: chainID,
          page: 1,
        })
      );
    } else {
      dispatch(
        getContributionRecordsByPage({
          address,
          provider,
          networkID: chainID,
          page: 1,
        })
      );
    }
    dispatch(
      getContributionData({
        address,
        provider,
        networkID: chainID,
      })
    );
    dispatch(
      getBurnAmount({
        provider,
        networkID: chainID,
        amount: "0",
        isStaking: false,
      })
    );
    dispatch(
      getBurnTokenAmount({
        provider,
        networkID: chainID,
        amount: "0",
        isStaking: false,
      })
    );
    dispatch(getBalances({ address, networkID: chainID, provider }));
    setDays(15);
    setNVBDays(15);
  };

  const burnToken = async () => {
    console.log("burnToken", { destroyedValue, ohmBalance });
    if (isNaN(destroyedValue) || destroyedValue === 0) {
      // eslint-disable-next-line no-alert
      return dispatch(error(t`Please enter a value!`));
    } else if (destroyedValue < 0) {
      return dispatch(error(t`Please enter a valid value!`));
    }
    if (Number(destroyedValue) > Number(ohmBalance)) {
      return dispatch(error(t`You cannot burn more than your NVB balance.`));
    }
    await dispatch(
      contributionBurn({
        address,
        provider,
        networkID: chainID,
        burnAmt: destroyedValue,
      })
    );
    dispatch(
      getContributionData({
        address,
        provider,
        networkID: chainID,
      })
    );
    setDestroyedValue("");
  };

  const approveForBurnToken = async (token: string) => {
    await dispatch(
      approveForDistributor({
        address,
        provider,
        networkID: chainID,
        token,
      })
    );
    dispatch(
      loadAccountDetails({
        address,
        provider,
        networkID: chainID,
      })
    );
  };

  const contributionFactor =
    Number(totalBondReward) +
      Number(totalContribution) +
      Number(totalNewContribution) +
      Number(totalTopContribution) -
      Number(burnAmt) * 4 >
      0
      ? Number(totalBondReward) +
      Number(totalContribution) +
      Number(totalNewContribution) +
      Number(totalTopContribution) -
      Number(burnAmt) * 4
      : 0;
  // const contributionFactor =
  //   Number(contributionPrincipalAmt) * 4 -
  //   (Number(totalBondReward) +
  //     Number(totalContribution) +
  //     Number(totalNewContribution) +
  //     Number(totalTopContribution) -
  //     Number(burnAmt));
  console.log(
    "contributionFactor",
    contributionFactor,
    Number(totalBondReward) +
    Number(totalContribution) +
    Number(totalNewContribution) +
    Number(totalTopContribution) -
    Number(burnAmt) * 4
  );
  console.log("rewardDistributorAllowance 123", {
    rewardDistributorAllowance,
    destroyedValue,
    bTokenrewardDistributorAllowance,
    days,
    burnAmt: burnObj[days],
    totalClaimableReward,
  });
  const minBurnAmt =
    contributionFactor > Number(contributionPrincipalAmt) * 4
      ? (contributionFactor - Number(contributionPrincipalAmt) * 4) / 4
      : 0;
  return (
    <Box
      display="flex"
      alignItems={"center"}
      flexDirection={"column"}
      sx={{
        position: "relative",
        mb: "100px",
      }}
    >
      <Box
        sx={{
          maxWidth: "1340px",
          width: { xs: "100%", sm: "97%" },
          pt: { sm: "87px", xs: "18px" },
          px: { xs: "15px", sm: "0" },
          position: "relative",
          zIndex: "1",
        }}
      >
        {/* Contribution top */}
        <Box
          sx={{
            mb: {
              xs: "20px",
              sm: "50px",
            },
          }}
          display="flex"
          alignItems={"center"}
        // justifyContent={"space-between"}
        >
          <Box
            display="flex"
            sx={{
              width: "100%",
              display: {
                xs: "none",
                sm: "flex",
              },
            }}
          >
            <Box
              sx={{
                mr: { sm: "20px", xs: "12px" },
              }}
            >
              <StarIcon />
            </Box>
            <Box
              display="flex"
              alignItems={"center"}
              sx={{
                width: "80%",
              }}
            >
              <ContributionIcon
                stlye={{
                  width: "100%",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              "&>img": {
                width: "80%",
              },
              display: {
                xs: "block",
                sm: "none",
              },
            }}
          >
            <img src={ContributionTitle} alt="contribution" />
          </Box>
        </Box>
        {/* Contribution bottom */}
        {/* Contribution tabs */}
        <Box
          sx={{
            mb: { sm: "50px", xs: "15px" },
          }}
        >
          <Tabs
            value={tab}
            onChange={changeTab}
            sx={{
              borderBottom: "1px solid #2D2D2D",
              "& .MuiTabs-indicator": {
                background: "#fff",
              },
            }}
            variant="scrollable"
          >
            <Tab
              label={t`Contribution team reward`}
              sx={{
                color: "#848E9C",
                fontSize: "16px",
                fontWeight: "400",
                textTransform: "capitalize",
                "&.Mui-selected": {
                  color: "#fff",
                  fontWeight: "600",
                },
              }}
            />
            <Tab
              label={t`Contribution ranking`}
              sx={{
                color: "#848E9C",
                fontSize: "16px",
                fontWeight: "400",
                textTransform: "capitalize",
                "&.Mui-selected": {
                  color: "#fff",
                  fontWeight: "600",
                },
              }}
            />
          </Tabs>
        </Box>
        {/* Contribution tab content */}
        <Box
          sx={
            {
              // borderRadius: { xs: "6px", sm: "10px" },
              // border: "1px solid #2d2d2d",
              // background: "#151515",
            }
          }
        >
          {tab == 0 ? (
            address ? (
              isVerified ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                  display="flex"
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {/* card1 */}
                  <Box
                    sx={{
                      borderRadius: { xs: "6px", sm: "10px" },
                      border: "1px solid #2d2d2d",
                      background: "#151515",
                      width: "100%",
                      py: { sm: "40px", xs: "25px" },
                      mb: {
                        sm: "15px",
                        xs: "8px",
                      },
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          mb: { xs: "30px", sm: "0" },
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems={"center"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                        >
                          <Typography
                            sx={{
                              color: "#848E9C",
                              fontSize: { xs: "14px", sm: "20px" },
                              fontWeight: "400",
                            }}
                          >
                            <Trans>Team Total Staked</Trans>
                          </Typography>
                          {address ? (
                            typeof teamStaking == "string" ? (
                              <Typography
                                sx={{
                                  color: "#EAECEF",
                                  fontSize: { xs: "20px", sm: "28px" },
                                  fontWeight: "600",
                                }}
                                align="center"
                              >
                                {customNumberFormat(Number(teamStaking), 4)}{" "}
                                sNVB
                              </Typography>
                            ) : (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={"center"}
                                sx={{ width: "100%" }}
                              >
                                <Skeleton width="150px" />
                              </Box>
                            )
                          ) : (
                            <Typography
                              sx={{
                                color: "#EAECEF",
                                fontSize: { xs: "20px", sm: "28px" },
                                fontWeight: "600",
                              }}
                              align="center"
                            >
                              0 sNVB
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box
                          display="flex"
                          alignItems={"center"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                        >
                          <Typography
                            sx={{
                              color: "#848E9C",
                              fontSize: { xs: "14px", sm: "20px" },
                              fontWeight: "400",
                            }}
                          >
                            <Trans>Team Contribution Value</Trans>
                          </Typography>
                          {address ? (
                            typeof teamContribution == "number" ? (
                              <Typography
                                sx={{
                                  color: "#EAECEF",
                                  fontSize: { xs: "20px", sm: "28px" },
                                  fontWeight: "600",
                                }}
                                align="center"
                              >
                                {customNumberFormat(
                                  Number(teamContribution),
                                  4
                                )}
                              </Typography>
                            ) : (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={"center"}
                                sx={{ width: "100%" }}
                              >
                                <Skeleton width="150px" />
                              </Box>
                            )
                          ) : (
                            <Typography
                              sx={{
                                color: "#EAECEF",
                                fontSize: { xs: "20px", sm: "28px" },
                                fontStyle: "normal",
                                fontWeight: "600",
                                width: "100%",
                              }}
                              align="center"
                            >
                              0 sNVB
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* card2 */}
                  <Box
                    sx={{
                      borderRadius: { xs: "6px", sm: "10px" },
                      border: "1px solid #2d2d2d",
                      background: "#151515",
                      width: "100%",
                      py: { sm: "40px", xs: "25px" },
                      mb: "30px",
                    }}
                  >
                    <Box
                      sx={{
                        mb: "50px",
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          sx={{
                            mb: { xs: "30px", sm: "0" },
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems={"center"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                          >
                            <Typography
                              sx={{
                                color: "#848E9C",
                                fontSize: { xs: "14px", sm: "18px" },
                                fontWeight: "400",
                              }}
                            >
                              <Trans>Minimum destruction</Trans>
                            </Typography>
                            <Typography
                              sx={{
                                color: "#EAECEF",
                                fontSize: { xs: "20px", sm: "28px" },
                                fontWeight: "600",
                              }}
                              align="center"
                            >
                              {customNumberFormat(Number(minBurnAmt), 4)} NVB
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          sx={{
                            mb: { xs: "30px", sm: "0" },
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems={"center"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                          >
                            <Typography
                              sx={{
                                color: "#848E9C",
                                fontSize: { xs: "14px", sm: "18px" },
                                fontWeight: "400",
                              }}
                            >
                              <Trans>
                                Maximum Staked Principal for Contribution Value
                              </Trans>
                            </Typography>
                            <Typography
                              sx={{
                                color: "#EAECEF",
                                fontSize: { xs: "20px", sm: "28px" },
                                fontWeight: "600",
                              }}
                              align="center"
                            >
                              {customNumberFormat(Number(maxPrincipal), 4)} sNVB
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          sx={{
                            mb: { xs: "30px", sm: "0" },
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems={"center"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                          >
                            <Box
                              display="flex"
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <Typography
                                sx={{
                                  color: "#848E9C",
                                  fontSize: { xs: "14px", sm: "18px" },
                                  fontWeight: "400",
                                }}
                              >
                                <Trans>Stake Base</Trans>
                              </Typography>
                              <InfoTooltip
                                message={t`Stake base: 4 times the stake amount`}
                              />
                            </Box>
                            {address ? (
                              contributionPrincipalAmt ? (
                                <Typography
                                  sx={{
                                    color: "#EAECEF",
                                    fontSize: { xs: "20px", sm: "28px" },
                                    fontWeight: "600",
                                  }}
                                  align="center"
                                >
                                  {customNumberFormat(
                                    Number(contributionPrincipalAmt) * 4,
                                    4
                                  )}
                                </Typography>
                              ) : (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent={"center"}
                                  sx={{ width: "100%" }}
                                >
                                  <Skeleton width="150px" />
                                </Box>
                              )
                            ) : (
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: { xs: "20px", sm: "28px" },
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  width: "100%",
                                }}
                                align="center"
                              >
                                <span>0</span>
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Box
                            display="flex"
                            alignItems={"center"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                          >
                            <Box
                              display="flex"
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <Typography
                                sx={{
                                  color: "#848E9C",
                                  fontSize: { xs: "14px", sm: "18px" },
                                  fontWeight: "400",
                                }}
                              >
                                <Trans>Contribution Factor</Trans>
                              </Typography>
                              <InfoTooltip
                                message={t`The system's contribution factor is automatically adjusted by the total contribution reward and total destruction`}
                              />
                            </Box>
                            <Typography
                              sx={{
                                color: "#EAECEF",
                                fontSize: { xs: "20px", sm: "28px" },
                                fontWeight: "600",
                              }}
                            >
                              {typeof contributionFactor == "number" ? (
                                customNumberFormat(contributionFactor, 4)
                              ) : (
                                <Skeleton width="80px" />
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      display="flex"
                      alignItems={"center"}
                      sx={{
                        px: { xs: "15px", sm: "30px" },
                      }}
                    >
                      <Grid
                        container
                        columnSpacing={{
                          sm: 2,
                          xs: 0,
                        }}
                      >
                        <Grid item xs={12} sm={6}>
                          <Box
                            display="flex"
                            justifyContent={"center"}
                            // alignItems={"center"}
                            flexDirection={"column"}
                            sx={{
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                // height: "100%",
                              }}
                              display="flex"
                              alignItems={"center"}
                              flexDirection={{
                                xs: "column",
                                sm: "row",
                              }}
                            >
                              <OutlinedInput
                                type="number"
                                placeholder={t`Please enter the amount destroyed`}
                                value={destroyedValue}
                                onChange={changeDestroyedValue}
                                sx={{
                                  width: {
                                    xs: "100%",
                                    sm: "auto",
                                  },
                                  flex: "1",
                                  height: "44px",
                                  borderRadius: "6px",
                                  border: "1px solid #2d2d2d",
                                  background: "#0c0c0c",
                                  color: "#fff",
                                  mr: {
                                    xs: "0",
                                    sm: "16px",
                                  },
                                  mb: {
                                    xs: "15px",
                                    sm: "0",
                                  },
                                }}
                              />
                              <Box
                                display="flex"
                                // alignItems={"center"}
                                sx={{
                                  width: "100%",
                                  mb: "15px",
                                  display: {
                                    xs: "flex",
                                    sm: "none",
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#EAECEF",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  <Trans>NVB Balance</Trans>:
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#EAECEF",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {isAppLoading ? (
                                    <Skeleton width="80px" />
                                  ) : (
                                    <>
                                      {customNumberFormat(
                                        Number(ohmBalance),
                                        4
                                      )}{" "}
                                      NVB
                                    </>
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: { xs: "100%", sm: "160px" },
                                  borderRadius: "6px",
                                  height: "44px",
                                  border: "1px solid #000",
                                  background: "#FCD000",
                                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                                  mb: "10px",
                                  "&:hover": {
                                    transform: "scale(1.02)",
                                  },
                                }}
                              >
                                {address ? (
                                  Number(rewardDistributorAllowance) >=
                                    Number(destroyedValue) ||
                                    !destroyedValue ? (
                                    <LoadingButton
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        background: "transparent",
                                        "&:hover": {
                                          background: "transparent",
                                        },
                                      }}
                                      loadingPosition="end"
                                      onClick={burnToken}
                                      disabled={
                                        isPendingTxn(
                                          pendingTransactions,
                                          "contribution_burn"
                                        ) || Number(destroyedValue) == 0
                                      }
                                      loading={isPendingTxn(
                                        pendingTransactions,
                                        "contribution_burn"
                                      )}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#000",
                                          fontSize: "16px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {txnButtonText(
                                          pendingTransactions,
                                          "contribution_burn",
                                          t`Confirm`
                                        )}
                                        {/* <Trans>Confirm</Trans> */}
                                      </Typography>
                                    </LoadingButton>
                                  ) : (
                                    <LoadingButton
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        background: "transparent",
                                        "&:hover": {
                                          background: "transparent",
                                        },
                                      }}
                                      loadingPosition="end"
                                      onClick={() => approveForBurnToken("ohm")}
                                      disabled={isPendingTxn(
                                        pendingTransactions,
                                        "contribution_approve"
                                      )}
                                      loading={isPendingTxn(
                                        pendingTransactions,
                                        "contribution_approve"
                                      )}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#000",
                                          fontSize: "16px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {" "}
                                        {txnButtonText(
                                          pendingTransactions,
                                          "contribution_approve",
                                          t`Approve`
                                        )}
                                        {/* <Trans>Approve</Trans> */}
                                      </Typography>
                                    </LoadingButton>
                                  )
                                ) : (
                                  <Button
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      background: "transparent",
                                      "&:hover": {
                                        background: "transparent",
                                      },
                                    }}
                                    onClick={connect}
                                    variant="contained"
                                  >
                                    <Typography
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <Trans>Connect Wallet</Trans>
                                    </Typography>
                                  </Button>
                                )}
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              alignItems={"center"}
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "flex",
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#EAECEF",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                <Trans>NVB Balance</Trans>:
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#EAECEF",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {isAppLoading ? (
                                  <Skeleton width="80px" />
                                ) : (
                                  <>
                                    {customNumberFormat(Number(ohmBalance), 4)}{" "}
                                    NVB
                                  </>
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box
                            display="flex"
                            alignItems={"flex-start"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                          >
                            <Typography
                              sx={{
                                color: "#848E9C",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                              align="left"
                            >
                              <Trans>Tips:</Trans>
                            </Typography>
                            <Typography
                              sx={{
                                color: "#848E9C",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              <Trans>
                                1. If the stake base is greater than the
                                contribution factor, the reward will be issued
                                normally; otherwise, the reward will stop.
                                Destruction will increase the pledge base.
                              </Trans>
                            </Typography>
                            <Typography
                              sx={{
                                color: "#848E9C",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              <Trans>
                                2. When the contribution factor exceeds the
                                staking base, the staking principal can be
                                increased to earn contribution rewards.
                              </Trans>
                            </Typography>
                            <Typography
                              sx={{
                                color: "#848E9C",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              <Trans>
                                3. When the contribution factor exceeds four
                                times the maximum staking principal, NVB must be
                                burned to reduce the contribution factor in
                                order to continue earning contribution rewards.
                              </Trans>
                            </Typography>
                            <Typography
                              sx={{
                                color: "#848E9C",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              <Trans>
                                4. The maximum value of the staking base is
                                equal to four times the maximum staking
                                principal.
                              </Trans>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  {/* card3 */}
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Grid
                      container
                      columnSpacing={2}
                      sx={{
                        mb: { xs: "30px", sm: "50px" },
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        sx={{
                          mb: { xs: "8px", sm: "0" },
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              borderRadius: "10px",
                              border: "1px solid #2d2d2d",
                              background: "#151515",
                              width: "100%",
                              height: { xs: "72px", sm: "105px" },
                              px: "20px",
                              py: "18px",
                              mb: { sm: "16px", xs: "8px" },
                            }}
                            display="flex"
                            alignItems={"cneter"}
                            flexDirection={"column"}
                          //  justifyContent={'center'}
                          >
                            <Box
                              sx={
                                {
                                  // mb: "10px",
                                }
                              }
                            >
                              <Typography
                                sx={{
                                  fontSize: { xs: "12px", sm: "18px" },
                                  color: "#848e9c",
                                  fontWeight: "400",
                                }}
                              >
                                <Trans>Contribution Weight Reward</Trans>
                              </Typography>
                            </Box>
                            {/* {true ? ( */}
                            {address ? (
                              totalContribution ? (
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontSize: { xs: "16px", sm: "24px" },
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    width: "100%",
                                  }}
                                >
                                  <span>
                                    {customNumberFormat(
                                      Number(totalContribution),
                                      4
                                    )}{" "}
                                    NVB
                                  </span>
                                </Typography>
                              ) : (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  // justifyContent="center"
                                  sx={{ width: "100%" }}
                                >
                                  <Skeleton width="150px" />
                                </Box>
                              )
                            ) : (
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: { xs: "16px", sm: "24px" },
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  width: "100%",
                                }}
                              >
                                <span>0 NVB</span>
                              </Typography>
                            )}
                          </Box>
                          <Box
                            sx={{
                              borderRadius: "10px",
                              border: "1px solid #2d2d2d",
                              background: "#151515",
                              width: "100%",
                              height: { xs: "72px", sm: "105px" },
                              px: "20px",
                              py: "18px",
                              mb: { sm: "16px", xs: "8px" },
                            }}
                            display="flex"
                            alignItems={"cneter"}
                            flexDirection={"column"}
                          >
                            <Box
                              sx={
                                {
                                  // mb: "10px",
                                }
                              }
                            >
                              <Typography
                                sx={{
                                  fontSize: { xs: "12px", sm: "18px" },
                                  color: "#848e9c",
                                  fontWeight: "400",
                                }}
                              >
                                <Trans>Daily new rewards</Trans>
                              </Typography>
                            </Box>
                            {address ? (
                              totalNewContribution ? (
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontSize: { xs: "16px", sm: "24px" },
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    width: "100%",
                                  }}
                                >
                                  <span>
                                    {customNumberFormat(
                                      Number(totalNewContribution),
                                      4
                                    )}{" "}
                                    NVB
                                  </span>
                                </Typography>
                              ) : (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  sx={{ width: "100%" }}
                                >
                                  <Skeleton width="150px" />
                                </Box>
                              )
                            ) : (
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: { xs: "16px", sm: "24px" },
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  width: "100%",
                                }}
                              >
                                <span>0 NVB</span>
                              </Typography>
                            )}
                          </Box>
                          <Box
                            sx={{
                              borderRadius: "10px",
                              border: "1px solid #2d2d2d",
                              background: "#151515",
                              width: "100%",
                              height: { xs: "72px", sm: "105px" },
                              px: "20px",
                              py: "18px",
                              mb: { sm: "16px", xs: "8px" },
                            }}
                            display="flex"
                            alignItems={"cneter"}
                            flexDirection={"column"}
                          //  justifyContent={'center'}
                          >
                            <Box
                              sx={
                                {
                                  // mb: "10px",
                                }
                              }
                            >
                              <Typography
                                sx={{
                                  fontSize: { xs: "12px", sm: "18px" },
                                  color: "#848e9c",
                                  fontWeight: "400",
                                }}
                              >
                                <Trans>Ranking Rewards</Trans>
                              </Typography>
                            </Box>
                            {address ? (
                              totalTopContribution ? (
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontSize: { xs: "16px", sm: "24px" },
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    width: "100%",
                                  }}
                                >
                                  <span>
                                    {customNumberFormat(
                                      Number(totalTopContribution),
                                      4
                                    )}{" "}
                                    NVB
                                  </span>
                                </Typography>
                              ) : (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  sx={{ width: "100%" }}
                                >
                                  <Skeleton width="150px" />
                                </Box>
                              )
                            ) : (
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: { xs: "16px", sm: "24px" },
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  width: "100%",
                                }}
                              >
                                <span>0 NVB</span>
                              </Typography>
                            )}
                          </Box>
                          <Box
                            sx={{
                              borderRadius: "10px",
                              border: "1px solid #2d2d2d",
                              background: "#151515",
                              width: "100%",
                              height: { xs: "72px", sm: "105px" },
                              px: { xs: "15px", sm: "20px" },
                              py: "18px",
                              // mb: {sm:"16px",xs:"8px"},
                            }}
                            display="flex"
                            alignItems={"cneter"}
                            flexDirection={"column"}
                          //  justifyContent={'center'}
                          >
                            <Box
                              sx={
                                {
                                  // mb: "10px",
                                }
                              }
                            >
                              <Typography
                                sx={{
                                  fontSize: { xs: "12px", sm: "18px" },
                                  color: "#848e9c",
                                  fontWeight: "400",
                                }}
                              >
                                <Trans>Bond Direct Referral Rewards</Trans>
                              </Typography>
                            </Box>
                            {address ? (
                              totalBondReward ? (
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontSize: { xs: "16px", sm: "24px" },
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    width: "100%",
                                  }}
                                >
                                  <span data-testid="index-value">
                                    {customNumberFormat(
                                      Number(totalBondReward),
                                      4
                                    )}{" "}
                                    NVB
                                  </span>
                                </Typography>
                              ) : (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  sx={{ width: "100%" }}
                                >
                                  <Skeleton width="150px" />
                                </Box>
                              )
                            ) : (
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: { xs: "16px", sm: "24px" },
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  width: "100%",
                                }}
                              >
                                <span>0 NVB</span>
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                            border: "1px solid #2d2d2d",
                            background: "#151515",
                          }}
                        >
                          <Tabs
                            sx={{
                              borderBottom: "1px solid #2D2D2D",
                              mb: {
                                xs: "15px",
                                sm: "20px",
                              },
                              "& .MuiTabs-indicator": {
                                background: "#fff",
                              },
                            }}
                            value={burnTab}
                            onChange={changeBurnTab}
                          >
                            <Tab
                              label={t`Burn WBNB`}
                              sx={{
                                color: "#fff",
                                fontSize: { sm: "16px", xs: "14px" },
                                fontWeight: "400",
                                textTransform: "capitalize",
                                "&.Mui-selected": {
                                  color: "#fff",
                                  fontWeight: "600",
                                },
                              }}
                            />
                            <Tab
                              label={t`Burn NVB`}
                              sx={{
                                color: "#fff",
                                fontSize: { sm: "16px", xs: "14px" },
                                fontWeight: "400",
                                textTransform: "capitalize",
                                "&.Mui-selected": {
                                  color: "#fff",
                                  fontWeight: "600",
                                },
                              }}
                            />
                          </Tabs>
                          {burnTab == 0 && (
                            <Box
                              sx={{
                                // pt: { xs: "20px", sm: "30px" },
                                px: { xs: "15px", sm: "30px" },
                                pb: { xs: "30px", sm: "0" },
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems={"center"}
                                sx={{
                                  mb: { xs: "20px", sm: "16px" },
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#848E9C",
                                    fontSize: { sm: "18px", xs: "14px" },
                                    fontWeight: "400",
                                    mr: 0.5,
                                  }}
                                >
                                  <Trans>WBNB Balance</Trans>:
                                </Typography>
                                {address ? (
                                  wbnbBalance ? (
                                    <Typography
                                      sx={{
                                        color: "#EAECEF",
                                        fontSize: { sm: "18px", xs: "14px" },
                                        fontWeight: "400",
                                        mr: 1,
                                      }}
                                    >
                                      {customNumberFormat(
                                        Number(wbnbBalance),
                                        4
                                      )}{" "}
                                      WBNB
                                    </Typography>
                                  ) : (
                                    // bTokenBalance ? (
                                    //   <Typography
                                    //     sx={{
                                    //       color: "#EAECEF",
                                    //       fontSize: { sm: "18px", xs: "14px" },
                                    //       fontWeight: "400",
                                    //       mr: 1,
                                    //     }}
                                    //   >
                                    //     {customNumberFormat(Number(bTokenBalance), 4)}{" "}
                                    //     AVC
                                    //   </Typography>
                                    <Skeleton width="80px" />
                                  )
                                ) : (
                                  <Typography
                                    sx={{
                                      color: "#EAECEF",
                                      fontSize: { sm: "18px", xs: "14px" },
                                      fontWeight: "400",
                                      mr: 1,
                                    }}
                                  >
                                    0 WBNB
                                    {/* 0 AVC */}
                                  </Typography>
                                )}
                                <Box
                                  sx={{
                                    borderRadius: "6px",
                                    border: "1px solid #000",
                                    background: "#FCD000",
                                    boxShadow: "-3px 3px 0px 0px #ECAA00",
                                    // width: "110px",
                                    width: "auto",
                                    px: "10px",
                                    height: "33px",
                                    "&:hover": {
                                      transform: "scale(1.02)",
                                    },
                                  }}
                                  display="flex"
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                >
                                  <Link
                                    href="/swap"
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  >
                                    <Button
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        background: "transparent",
                                        "&:hover": {
                                          background: "transparent",
                                        },
                                      }}
                                    // onClick={handleBuyBToken}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#000",
                                          fontSize: "16px",
                                          fontWeight: "500",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <Trans>Buy $WBNB</Trans>
                                        {/* <Trans>Buy $AVC</Trans> */}
                                      </Typography>
                                    </Button>
                                  </Link>
                                </Box>
                              </Box>
                              {/* select option */}
                              <Grid
                                container
                                rowSpacing={2.5}
                                columnSpacing={2}
                                sx={{
                                  mb: { sm: "40px", xs: "30px" },
                                }}
                              >
                                {/* 15d */}
                                <Grid item xs={12} sm={6}>
                                  <Box
                                    display="flex"
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{
                                      cursor: "pointer",
                                      border:
                                        days == 15
                                          ? "1px solid #FCD000"
                                          : "1px solid #2d2d2d",
                                      p: "14px",
                                      borderRadius: "6px",
                                    }}
                                    onClick={() => handleSelect(15)}
                                  >
                                    <Typography
                                      sx={{
                                        color:
                                          days == 15 ? "#fcd000" : "#eaecef",
                                        fontSize: { sm: "16px", xs: "14px" },
                                      }}
                                    >
                                      <Trans>15D</Trans>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color:
                                          days == 15 ? "#fcd000" : "#eaecef",
                                        fontSize: { sm: "16px", xs: "14px" },
                                      }}
                                    >
                                      <Trans>Estimated destruction</Trans>:{" "}
                                      {customNumberFormat(Number(burnAmt15), 4)}{" "}
                                      WBNB
                                      {/* {customNumberFormat(Number(burnAmt30), 4)} AVC */}
                                    </Typography>
                                  </Box>
                                </Grid>
                                {/* 30d */}
                                <Grid item xs={12} sm={6}>
                                  <Box
                                    display="flex"
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{
                                      cursor: "pointer",
                                      border:
                                        days == 30
                                          ? "1px solid #FCD000"
                                          : "1px solid #2d2d2d",
                                      p: "14px",
                                      borderRadius: "6px",
                                    }}
                                    onClick={() => handleSelect(30)}
                                  >
                                    <Typography
                                      sx={{
                                        color:
                                          days == 30 ? "#fcd000" : "#eaecef",
                                        fontSize: { sm: "16px", xs: "14px" },
                                      }}
                                    >
                                      <Trans>30D</Trans>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color:
                                          days == 30 ? "#fcd000" : "#eaecef",
                                        fontSize: { sm: "16px", xs: "14px" },
                                      }}
                                    >
                                      <Trans>Estimated destruction</Trans>:{" "}
                                      {customNumberFormat(Number(burnAmt30), 4)}{" "}
                                      WBNB
                                      {/* {customNumberFormat(Number(burnAmt30), 4)} AVC */}
                                    </Typography>
                                  </Box>
                                </Grid>
                                {/* 60d */}
                                <Grid item xs={12} sm={6}>
                                  <Box
                                    display="flex"
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{
                                      cursor: "pointer",
                                      border:
                                        days == 60
                                          ? "1px solid #FCD000"
                                          : "1px solid #2d2d2d",
                                      p: "14px",
                                      borderRadius: "6px",
                                    }}
                                    onClick={() => handleSelect(60)}
                                  >
                                    <Typography
                                      sx={{
                                        color:
                                          days == 60 ? "#fcd000" : "#eaecef",
                                        fontSize: { sm: "16px", xs: "14px" },
                                      }}
                                    >
                                      <Trans>60D</Trans>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color:
                                          days == 60 ? "#fcd000" : "#eaecef",
                                        fontSize: { sm: "16px", xs: "14px" },
                                      }}
                                    >
                                      <Trans>Estimated destruction</Trans>:{" "}
                                      {customNumberFormat(Number(burnAmt60), 4)}{" "}
                                      WBNB
                                      {/* {customNumberFormat(Number(burnAmt60), 4)} AVC */}
                                    </Typography>
                                  </Box>
                                </Grid>
                                {/* 100d */}
                                <Grid item xs={12} sm={6}>
                                  <Box
                                    display="flex"
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{
                                      cursor: "pointer",
                                      border:
                                        days == 100
                                          ? "1px solid #FCD000"
                                          : "1px solid #2d2d2d",
                                      p: "14px",
                                      borderRadius: "6px",
                                    }}
                                    onClick={() => handleSelect(100)}
                                  >
                                    <Typography
                                      sx={{
                                        color:
                                          days == 100 ? "#fcd000" : "#eaecef",
                                        fontSize: { sm: "16px", xs: "14px" },
                                      }}
                                    >
                                      <Trans>100D</Trans>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color:
                                          days == 100 ? "#fcd000" : "#eaecef",
                                        fontSize: { sm: "16px", xs: "14px" },
                                      }}
                                    >
                                      <Trans>Estimated destruction</Trans>:{" "}
                                      {customNumberFormat(
                                        Number(burnAmt100),
                                        4
                                      )}{" "}
                                      WBNB
                                      {/* {customNumberFormat(Number(burnAmt100), 4)} AVC */}
                                    </Typography>
                                  </Box>
                                </Grid>
                                {/* 150d */}
                                <Grid item xs={12} sm={6}>
                                  <Box
                                    display="flex"
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{
                                      cursor: "pointer",
                                      border:
                                        days == 150
                                          ? "1px solid #FCD000"
                                          : "1px solid #2d2d2d",
                                      p: "14px",
                                      borderRadius: "6px",
                                    }}
                                    onClick={() => handleSelect(150)}
                                  >
                                    <Typography
                                      sx={{
                                        color:
                                          days == 150 ? "#fcd000" : "#eaecef",
                                        fontSize: { sm: "16px", xs: "14px" },
                                      }}
                                    >
                                      <Trans>150D</Trans>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color:
                                          days == 150 ? "#fcd000" : "#eaecef",
                                        fontSize: { sm: "16px", xs: "14px" },
                                      }}
                                    >
                                      <Trans>Estimated destruction</Trans>:{" "}
                                      {customNumberFormat(
                                        Number(burnAmt150),
                                        4
                                      )}{" "}
                                      WBNB
                                      {/* {customNumberFormat(Number(burnAmt150), 4)} AVC */}
                                    </Typography>
                                  </Box>
                                </Grid>
                                {/* 180d */}
                                <Grid item xs={12} sm={6}>
                                  <Box
                                    display="flex"
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{
                                      cursor: "pointer",
                                      border:
                                        days == 180
                                          ? "1px solid #FCD000"
                                          : "1px solid #2d2d2d",
                                      p: "14px",
                                      borderRadius: "6px",
                                    }}
                                    onClick={() => handleSelect(180)}
                                  >
                                    <Typography
                                      sx={{
                                        color:
                                          days == 180 ? "#fcd000" : "#eaecef",
                                        fontSize: { sm: "16px", xs: "14px" },
                                      }}
                                    >
                                      <Trans>180D</Trans>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color:
                                          days == 180 ? "#fcd000" : "#eaecef",
                                        fontSize: { sm: "16px", xs: "14px" },
                                      }}
                                    >
                                      <Trans>Estimated destruction</Trans>:{" "}
                                      {customNumberFormat(
                                        Number(burnAmt180),
                                        4
                                      )}{" "}
                                      WBNB
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Box
                                sx={{
                                  width: "100%",
                                  height: { sm: "65px", xs: "auto" },
                                  borderRadius: "6px",
                                  border: "1px solid #2d2d2d",
                                  background: "#0c0c0c",
                                  mb: {
                                    xs: "20px",
                                    sm: "16px",
                                  },
                                  px: { sm: "20px", xs: "15px" },
                                  py: { sm: "0", xs: "15px" },
                                }}
                                display="flex"
                                flexDirection={{
                                  xs: "column",
                                  sm: "row",
                                }}
                                alignItems={"center"}
                                justifyContent={{
                                  xs: "flex-start",
                                  sm: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    flex: { sm: "1" },
                                    width: "100%",
                                  }}
                                  display="flex"
                                  alignItems={{ sm: "center" }}
                                  flexDirection={{
                                    xs: "column",
                                    sm: "row",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#848e9c",
                                      fontSize: { xs: "16px", sm: "22px" },
                                      fontWeight: "400",
                                      mr: 0.5,
                                    }}
                                  >
                                    <Trans>Contribution team reward：</Trans>
                                  </Typography>
                                  {address ? (
                                    totalClaimableReward ? (
                                      <Typography
                                        sx={{
                                          color: "#EAECEF",
                                          fontSize: { xs: "16px", sm: "22px" },
                                          fontWeight: "600",
                                        }}
                                      >
                                        {customNumberFormat(
                                          Number(totalClaimableReward),
                                          4
                                        )}{" "}
                                        NVB
                                      </Typography>
                                    ) : (
                                      <Skeleton width="80px" />
                                    )
                                  ) : (
                                    <Typography
                                      sx={{
                                        color: "#EAECEF",
                                        fontSize: { xs: "16px", sm: "22px" },
                                        fontWeight: "600",
                                      }}
                                    >
                                      0 NVB
                                    </Typography>
                                  )}
                                </Box>
                                <Box
                                  sx={{
                                    width: "200px",
                                    borderRadius: "6px",
                                    height: "44px",
                                    border: "1px solid #000",
                                    background: "#FCD000",
                                    boxShadow: "-3px 3px 0px 0px #ECAA00",
                                    mb: { xs: "10px", sm: "0" },
                                    display: { xs: "none", sm: "inline-block" },
                                    "&:hover": {
                                      transform: "scale(1.02)",
                                    },
                                  }}
                                >
                                  {address ? (
                                    Number(bTokenrewardDistributorAllowance) >
                                      Number(burnObj[days]) ? (
                                      <LoadingButton
                                        sx={{
                                          width: "100%",
                                          height: "100%",
                                          background: "transparent",
                                          "&:hover": {
                                            background: "transparent",
                                          },
                                        }}
                                        onClick={() => claimTeamReward(false)}
                                        variant="contained"
                                        // contribution_claim
                                        disabled={
                                          isPendingTxn(
                                            pendingTransactions,
                                            "contribution_claim"
                                          ) || Number(totalClaimableReward) == 0
                                        }
                                        loading={isPendingTxn(
                                          pendingTransactions,
                                          "contribution_claim"
                                        )}
                                        loadingPosition="end"
                                      >
                                        <Typography
                                          sx={{
                                            color: "#000",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {txnButtonText(
                                            pendingTransactions,
                                            "contribution_claim",
                                            t`Confirm`
                                          )}
                                        </Typography>
                                      </LoadingButton>
                                    ) : (
                                      <LoadingButton
                                        sx={{
                                          width: "100%",
                                          height: "100%",
                                          background: "transparent",
                                          "&:hover": {
                                            background: "transparent",
                                          },
                                        }}
                                        loadingPosition="end"
                                        onClick={() =>
                                          approveForBurnToken("bToken")
                                        }
                                        disabled={isPendingTxn(
                                          pendingTransactions,
                                          "contribution_approve"
                                        )}
                                        loading={isPendingTxn(
                                          pendingTransactions,
                                          "contribution_approve"
                                        )}
                                        variant="contained"
                                      >
                                        <Typography
                                          sx={{
                                            color: "#000",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {txnButtonText(
                                            pendingTransactions,
                                            "contribution_approve",
                                            t`Approve`
                                          )}
                                        </Typography>
                                      </LoadingButton>
                                    )
                                  ) : (
                                    <Button
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        background: "transparent",
                                        "&:hover": {
                                          background: "transparent",
                                        },
                                      }}
                                      onClick={connect}
                                      variant="contained"
                                    >
                                      <Typography
                                        sx={{
                                          color: "#000",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        <Trans>Connect Wallet</Trans>
                                      </Typography>
                                    </Button>
                                  )}
                                </Box>
                              </Box>
                              {/* mobile claim button */}
                              <Box
                                sx={{
                                  width: "100%",
                                  borderRadius: "6px",
                                  height: "44px",
                                  border: "1px solid #000",
                                  background: "#FCD000",
                                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                                  mb: { xs: "10px", sm: "0" },
                                  display: { xs: "block", sm: "none" },
                                  "&:hover": {
                                    transform: "scale(1.02)",
                                  },
                                }}
                              >
                                {address ? (
                                  Number(bTokenrewardDistributorAllowance) >
                                    Number(burnObj[days]) ? (
                                    <LoadingButton
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        background: "transparent",
                                        "&:hover": {
                                          background: "transparent",
                                        },
                                      }}
                                      onClick={() => claimTeamReward(false)}
                                      variant="contained"
                                      // contribution_claim
                                      disabled={
                                        isPendingTxn(
                                          pendingTransactions,
                                          "contribution_claim"
                                        ) || Number(totalClaimableReward) == 0
                                      }
                                      loading={isPendingTxn(
                                        pendingTransactions,
                                        "contribution_claim"
                                      )}
                                      loadingPosition="end"
                                    >
                                      <Typography
                                        sx={{
                                          color: "#000",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {txnButtonText(
                                          pendingTransactions,
                                          "contribution_claim",
                                          t`Confirm`
                                        )}
                                      </Typography>
                                    </LoadingButton>
                                  ) : (
                                    <LoadingButton
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        background: "transparent",
                                        "&:hover": {
                                          background: "transparent",
                                        },
                                      }}
                                      loadingPosition="end"
                                      onClick={() =>
                                        approveForBurnToken("bToken")
                                      }
                                      disabled={isPendingTxn(
                                        pendingTransactions,
                                        "contribution_approve"
                                      )}
                                      loading={isPendingTxn(
                                        pendingTransactions,
                                        "contribution_approve"
                                      )}
                                      variant="contained"
                                    >
                                      <Typography
                                        sx={{
                                          color: "#000",
                                          fontSize: "16px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {txnButtonText(
                                          pendingTransactions,
                                          "contribution_approve",
                                          t`Approve`
                                        )}
                                      </Typography>
                                    </LoadingButton>
                                  )
                                ) : (
                                  <Button
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      background: "transparent",
                                      "&:hover": {
                                        background: "transparent",
                                      },
                                    }}
                                    onClick={connect}
                                    variant="contained"
                                  >
                                    <Typography
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <Trans>Connect Wallet</Trans>
                                    </Typography>
                                  </Button>
                                )}
                                {/* <Button
                            sx={{
                              width: "100%",
                              height: "100%",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            }}
                            onClick={handleStakeInterest}
                            variant="contained"
                          >
                            <Typography
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              <Trans>Claim</Trans>
                            </Typography>
                          </Button> */}
                              </Box>
                              <Box
                                display="flex"
                                alignItems={"center"}
                                justifyContent={"center"}
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#848e9c",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  <Trans>
                                    Tip: To claim the interest, you must destroy
                                    the corresponding amount of WBNB
                                  </Trans>
                                  {/* <Trans>
                              Tip: To claim the interest, you must destroy the
                              corresponding amount of AVC
                            </Trans> */}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                          {burnTab == 1 && (
                            <BurnNVBTab
                              nvbDays={nvbDays}
                              claimTeamReward={claimTeamReward}
                              handleNVBSelect={handleNVBSelect}
                              approveForBurnToken={approveForBurnToken}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* card4 record & flow */}
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Tabs
                      sx={{
                        borderBottom: "1px solid #2D2D2D",
                        mb: {
                          xs: "15px",
                          sm: "40px",
                        },
                        "& .MuiTabs-indicator": {
                          background: "#fff",
                        },
                        "& .MuiTabScrollButton-root": {
                          width: "20px",
                          display: {
                            xs: "inline-flex",
                            sm: "none",
                          },
                          "&.Mui-disabled": {
                            display: "none",
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#fff",
                        },
                      }}
                      variant="scrollable"
                      value={recordTab}
                      onChange={changeRecordTab}
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      <Tab
                        label={t`Burn WBNB`}
                        sx={{
                          color: "#fff",
                          fontSize: { sm: "16px", xs: "14px" },
                          fontWeight: "400",
                          textTransform: "capitalize",
                          "&.Mui-selected": {
                            color: "#fff",
                            fontWeight: "600",
                          },
                        }}
                      />
                      <Tab
                        label={t`Burn NVB`}
                        sx={{
                          color: "#fff",
                          fontSize: { sm: "16px", xs: "14px" },
                          fontWeight: "400",
                          textTransform: "capitalize",
                          "&.Mui-selected": {
                            color: "#fff",
                            fontWeight: "600",
                          },
                        }}
                      />
                      <Tab
                        label={t`flow`}
                        sx={{
                          color: "#fff",
                          fontSize: { sm: "16px", xs: "14px" },
                          fontWeight: "400",
                          textTransform: "capitalize",
                          "&.Mui-selected": {
                            color: "#fff",
                            fontWeight: "600",
                          },
                        }}
                      />
                    </Tabs>
                    {recordTab == 0 ? (
                      <Record
                        recordData={contributionRecords}
                        isStakeRecord={false}
                        isBurnToken={false}
                      />
                    ) : recordTab == 1 ? (
                      <Record
                        recordData={contributionTokenRecords}
                        isStakeRecord={false}
                        isBurnToken={true}
                      />
                    ) : (
                      <FlowData />
                    )}
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                  display="flex"
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    sx={{
                      borderRadius: { xs: "6px", sm: "10px" },
                      border: "1px solid #2d2d2d",
                      background: "#151515",
                      width: "100%",
                      py: { sm: "40px", xs: "25px" },
                      mb: {
                        sm: "15px",
                        xs: "8px",
                      },
                    }}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box
                      sx={{
                        width: { xs: "100%", sm: "160px" },
                        borderRadius: "6px",
                        height: "44px",
                        border: "1px solid #000",
                        background: "#FCD000",
                        boxShadow: "-3px 3px 0px 0px #ECAA00",
                        "&:hover": {
                          transform: "scale(1.02)",
                        },
                      }}
                    >
                      <LoadingButton
                        sx={{
                          width: "100%",
                          height: "100%",
                          background: "transparent",
                          "&:hover": {
                            background: "transparent",
                          },
                        }}
                        loadingPosition="end"
                        loading={loadingSign}
                        onClick={verifyWallet}
                      >
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          <Trans>Verify yourself</Trans>
                        </Typography>
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>
              )
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                }}
                display="flex"
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box
                  sx={{
                    borderRadius: { xs: "6px", sm: "10px" },
                    border: "1px solid #2d2d2d",
                    background: "#151515",
                    width: "100%",
                    py: { sm: "40px", xs: "25px" },
                    mb: {
                      sm: "15px",
                      xs: "8px",
                    },
                  }}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "160px" },
                      borderRadius: "6px",
                      height: "44px",
                      border: "1px solid #000",
                      background: "#FCD000",
                      boxShadow: "-3px 3px 0px 0px #ECAA00",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                    }}
                  >
                    <Button
                      sx={{
                        width: "100%",
                        height: "100%",
                        background: "transparent",
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                      onClick={connect}
                      variant="contained"
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        <Trans>Connect Wallet</Trans>
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          ) : (
            <RankingList />
          )}
        </Box>
      </Box>
      {/* left top bg */}
      <Box
        sx={{
          position: "absolute",
          left: "-20px",
          top: { xs: "-50px", sm: "-25px" },
          width: { xs: "186px", sm: "390px" },
          height: { xs: "309px", sm: "509px" },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={FooterBg} />
      </Box>
    </Box>
  );
};

export default Contribution;
